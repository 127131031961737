<template>
  <div class="log">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <el-row style="margin-bottom: 20px">
          <el-col :lg="12" :md="12" :sm="24" :xs="24">

            <h3 class="title fw-normal text-dark mb-0 ls-05">{{ 'Application Logs' }}</h3>
          </el-col>
          </el-row>
    <div class="paging">
      <div class="table">
        <el-row class="bottom-border">
          <el-col :lg="2" class="bold">Type</el-col>
          <el-col :lg="5" class="bold">Date & Time</el-col>
          <el-col :lg="5" class="bold"> Module</el-col>
          <el-col :lg="12" class="bold"> Message</el-col>
        </el-row>
        <el-row class="bottom-border" v-for="item in pagedLogData" :key="item.timeStamp">
          <el-col :lg="2">
            <div class="label">
              <div class="first">
                <div class="button" v-if="item.level == 'error'">
                  <div class="icons">
                    <el-button type="text" icon="el-icon-error" style="color:crimson">{{ item.level }}</el-button>
                  </div>
                </div>
                <div class="button" v-if="item.level == 'info'">
                  <div class="icons">
                    <el-button type="text" icon="el-icon-info" style="color:green">{{ item.level }}</el-button>
                  </div>
                </div>
                <div class="button" v-else-if="item.level == 'warn'">
                  <div class="icons">
                    <el-button type="text" icon="el-icon-warning" style="color:gold">{{ item.level }}</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="5">
            <div class="label">
              <div class="second">
                <div class="timer"><el-button type="text" icon="el-icon-timer"></el-button></div>
                <div class="timeStamp" style="margin-top: 10px;">{{ item.timestamp }}</div>
              </div>
            </div>
          </el-col>
          <el-col :lg="5">
            <div class="label">
              <div class="third">
                <div class="actionModule">{{ item.actionModule }}</div>
              </div>
            </div>
          </el-col>
          <el-col :lg="12">
            <div class="label">
              <div class="fourth">
                <div class="para">
                  <p>{{ item.message }} by {{ item.LoginUser }}</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row class="pagination">
        <div>
          <el-col :span="12">
            <div class="block">
              <span class="demonstration">
                <el-pagination 
                background
                :total="total"
                @current-change="selectPage"
                @size-change="changeLogsPerPage" 
                :page-size="selectedLogsPerPage"
                :page-sizes="logsPerPageOptions"
                layout="prev, pager, next, jumper, sizes"
                >
                </el-pagination>
              </span>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
    </el-col>
    </el-row>
    
  </div>
</template>

<script>
 import axios from "@/config/axios";
export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      logData:[],
      currentPage: 1,
      logsPerPageOptions: [5, 10, 12,15,20],
      selectedLogsPerPage: 12,
      
    };
  },
  computed: {
    total() {
      return this.logData.length;
    },
    pagedLogData() {
      const start = (this.currentPage - 1) * this.selectedLogsPerPage;
      const end = start + this.selectedLogsPerPage;
      return this.logData.slice(start, end);
    },
  },
  methods: {    
    async getLogData() {
      await axios.get("http://localhost:3003/v1.0/logInfoToJson").then(async (response) => {
        this.logData = await response.data;
        console.log(this.logData)
      })
        .catch((err) => {
          console.log(err)
        })
    },
    selectPage(page) {
      this.currentPage = page;
    },
    changeLogsPerPage(perPage) {
      this.selectedLogsPerPage = perPage;
      this.currentPage = 1;
    },
  },
  mounted(){
    this.getLogData()
  }
}
</script>

<style scoped>
.dataButton {
  size: 3px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.second {
  display: flex;
  flex-direction: row;
}

.third {
  margin-top: 8px;
}

.fourth {
  margin-top: 8px;
}

.button {
  display: flex;
  flex-direction: row;
}

.bottom-border {
  border-bottom: 1px solid rgb(226, 227, 231);
}
.bold{
  font-weight: 600 !important;
}

.row {
  display: flex;
  flex-direction: row;
}
</style>